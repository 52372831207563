module.exports = {
    getCreatorList:{
        url:'/api/frontend/creator/list',
        method:'GET'
    },
    getCreatorDetail:{
        url:'/api/frontend/creator/detail',
        method:'GET'
    },
    getCreatorVedioList:{
		url:'/api/frontend/post/list',
		method:"GET"
	},
    syncVedio:{
		url:'/api/frontend/userPost/syncPost',
		method:"POST"
	},
	getYoutubeVedio:{
		url:'/api/frontend/userPost/getYoutubeVideo',
		method:"GET"
	},
	deleteVedio:{
		url:'/api/frontend/userPost/delPost',
		method:"POST"
	},
	changeVedioState:{
		url:'/api/frontend/userPost/upDown',
		method:"POST"
	},
	getVedioStatistcs:{
		url:'/api/frontend/userPost/dataReport',
		method:"GET"
	},
	getTypeList:{
		url:'/api/frontend/postReward/typeList',
		method:"GET"
	},
	getCategoryList:{
		url:'/api/frontend/post/categoryList',
		method:"GET"
	},
	getRewardList:{
		url:'/api/frontend/postReward/list',
		method:"GET"
	},
	addReward:{
		url:'/api/frontend/postReward/addReward',
		method:"POST"
	},
	deleteReward:{
		url:'/api/frontend/postReward/cancel',
		method:"POST"
	},
	FollowUser:{
		url:'/api/frontend/user/follow',
		method:"POST"
	},
	uploadVideo: {
		url: '/api/frontend/userPost/storePost',
		method: 'POST',
	}
}