<template>
  <footer class="flex flex-jc flex-ac" :style="{ width: `${wdWidth}px` }">
    <img v-show="wdWidth > 900" class="logo" src="@/assets/logo.png" />
    <a href="https://aicean.ai/" class="link"
      >Copyright 2024 AICEAN. All Right Reserved.</a
    >
    <div class="oprate flex flex-jc flex-ac">
      <div class="oprate_left" @click="showFlag = !showFlag">
        <div class="pop" @click.stop="changeLang" :style="PopStatus">
          <span class="pop_item" data-lg="en">English</span>
          <span class="pop_item" data-lg="cn">中文</span>
        </div>
        <img class="global" src="@/assets/global.png" />
        <span class="global_txt" data-locale="tag">English</span>
        <image class="global_arrow" src="@/assets/arrow.png" />
      </div>
      <div class="oprate_right" style="display: inline-block">
        <!-- <img class="channels" src="@/assets/youtbe.png" />
            <img class="channels" src="@/assets/instagram.png" />
            <img class="channels" src="@/assets/twitter.png" /> -->
        <el-tooltip content="E-mail">
          <!-- <a class="channels" href="mailto:hi@speckies.io" target="_blank" ></a> -->
          <a
            class="channels"
            href="mailto:support@aicean.ai"
            target="_blank"
          ></a>
        </el-tooltip>
        <el-tooltip content="telegram">
          <!-- <a class="channels" href="https://discord.gg/fMFQZgAR6Z" target="_blank" ></a> -->
          <a class="channels" href="https://t.me/Aicean" target="_blank"></a>
        </el-tooltip>
        <el-tooltip content="Twitter / X">
          <a
            class="channels"
            href="https://x.com/Aicean_ai"
            target="_blank"
          ></a>
        </el-tooltip>
        <el-tooltip content="instagram">
          <a
            class="channels"
            href="https://www.instagram.com/aicean_ai"
            target="_blank"
          ></a>
        </el-tooltip>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      showFlag: false,
    }
  },
  methods: {
    changeLang(e) {
      console.dir(e.target.dataset.lg)
    },
  },
  computed: {
    ...mapGetters(['wdWidth', 'isMoblie']),
    PopStatus() {
      return {
        height: this.showFlag ? '7.8rem' : 0,
        opacity: this.showFlag ? 1 : 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  // position: sticky;
  // z-index:100;
  // bottom:0;
  width: 100%;
  // height:5.13rem;
  padding: 1.25rem;
  border-top: 0.13rem solid rgba(255, 255, 255, 0.1);
}

.logo {
  width: 12rem;
  height: 2.63rem;
  object-fit: contain;
  // margin-top:1.13rem;
  margin-left: 0;
}

.link {
  color: var(--gray);
  font-size: 1rem;
  line-height: 1.25rem;
  width: 23.69rem;
  white-space: nowrap;
  font-family: SVN-Gilroy, SVN;
  margin: 0 15.19rem 0 22.44rem;
  display: inline-block;
  text-decoration: none;
}

.oprate {
  // height:0;
  &_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    pointer-events: none;
    visibility: hidden;
    margin-right: 2.69rem;
  }
}

.pop {
  position: absolute;
  z-index: 50;
  bottom: 4.56rem;
  height: 0rem;
  width: 10.6rem;
  right: -1.52rem;
  transition: all 0.3s;
  background: url(@/assets/pop.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  opacity: 0;

  &_item {
    color: var(--gray);
    font-size: 0.88rem;
    line-height: 1.33rem;
    letter-spacing: 0.02rem;
    width: 10.6rem;
    font-family: SVN-GilroyMedium, SVN;
    text-align: center;
    display: block;
    padding: 0.94rem 0;
    z-index: 100;
    border-bottom: 0.06rem solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
    &:nth-last-of-type(1) {
      border-bottom: none;
    }
  }
}

.global {
  height: 1.38rem;
  width: 1.38rem;
  margin-right: 0.38rem;
  object-fit: contain;

  &_txt {
    color: var(--gray);
    font-size: 1.13rem;
    line-height: 1.44rem;
    letter-spacing: 0.05rem;
    display: inline-block;
    width: 4rem;
    margin-right: 0.13rem;
    vertical-align: center;
    transition: all 0.3s;
  }

  &_arrow {
    height: 0.75rem;
    width: 0.75rem;
  }
}

.channels {
  height: 2.2rem;
  width: 2.2rem;
  object-fit: contain;
  margin-left: 1.25rem;
  cursor: pointer;
}

.channels:nth-of-type(1) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/emailIcon.png);
}

.channels:nth-of-type(2) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/tg.png);
}

.channels:nth-of-type(3) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/twitter.png);
}
.channels:nth-of-type(4) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/ins.png);
}

.oprate_right > .channels:nth-of-type(1) {
  margin-left: 0 !important;
}

@media screen and (max-width: 900px) {
  footer {
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5rem 0;
  }
  .link {
    margin: 0;
    margin-bottom: 0.5rem;
    width: auto;
    transform: scale(0.8);
  }
  .oprate {
    margin: 0;
    &_left {
      display: none;
    }
  }
}
</style>
