import store from '@/store'

export function init(){
    store.commit('common/SET_SIZE',{height:window.innerHeight,width:window.innerWidth})
    
    window.onresize=function(){
        store.commit('common/SET_SIZE',{height:window.innerHeight,width:window.innerWidth})
    }
}

export function _isMobile() {
    console.log(navigator.userAgent)
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}

const chars='0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

export function random64(){
	let str =''
	for(let i=0;i<64;i++){
		str+=chars[Math.round(Math.random()*chars.length)];
	}
	return str;
}

let th_flag

export function _throttle(fn,delay=1000,immediate=true,...args){
    let timer=null
	if(!immediate){
		if(!th_flag){
			th_flag=true;
			timer=setTimeout(()=>{
				typeof fn === 'function' && fn.apply(this,args)
				th_flag=false;
			},delay)
		}
	}else{
		if(!th_flag){
			th_flag=true;
            console.log(Object.prototype.toString.call(fn))
			typeof fn === 'function' && fn.apply(this,args)
			timer=setTimeout(()=>{
				th_flag=false;
			},delay)
		}
	}
    return timer;
}


/**
   * 创建随机字符串
   * @param {String | Number } num
   * @returns {string}
   **/
export function randomString(num) {
	const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
	let res = ''
	for (let i = 0; i < num; i++) {
	res += chars[Math.ceil(Math.random() * 35)]
	}
	return res
}

export function remToPx(remValue) {
	const baseFontSize = parseInt(getComputedStyle(document.documentElement).fontSize);
	return baseFontSize * remValue;
}