<template>
    <div ref="boxBg" style="height:100vh;width:100vw;position:fixed;z-index:1000;pointer-events:none;" >
        <el-tooltip class="box_img_wrapper" content="You Tasks " >
            <img class="box_img" style="pointer-events:auto;" src="@/assets/task.png" @click.stop="changeFlag(arguments,true)" />
        </el-tooltip>
        <div 
            class="box flex flex-jc flex-ac" 
            style="pointer-events:auto;" 
            :style="{
                pointerEvents:showFlag?'auto':'none',
                visibility:showFlag?'visible':'hidden'
            }" >
            <div class="box_content flex flex-col flex-jfs flex-ac" :style="[popStyle]" >
                <img class="box_content_shrink" src="@/assets/shrink.svg"  @click.stop="changeFlag(arguments,false)" />
                <span 
                    class="box_content_title" 
                    >The system will automatically issue the corresponding token at 0:00 according to the daily contribution point</span
                >
                <div class="box_content_data flex flex-jsb flex-ac" >
                    <div  v-for="(item,index) in typeList"  :key="index"  class="data flex flex-col flex-jfs flex-ac" >
                        <img class="data_icon" :src="item.icon" />
                        <span class="data_num" >{{numFilter(item.value)}}</span>
                        <span class="data_title" >{{item.title}}</span>
                    </div>
                </div>
                <div ref="tagWrapper" class="tag flex flex-jfs flex-ac" >
                    <span 
                        class="tag_item" 
                        ref="tag"
                        v-for="(tItem,tIndex) in tagList"
                        v-show="!( tIndex==0 && tItem.key == 'new_user' && !taskDetail.is_has_new_user_task )" 
                        :key="tIndex" 
                        @click.stop="tagChange(tIndex)" 
                        :style="{fontFamily:tagIndex==tIndex?'SVN-GilroySemiBold':'SVN-Gilroy'}" >{{tItem.name}}</span>
                    <span class="tag_history" @click.stop="jumpHistory" >History</span>
                    <div class="tag_bar" :style="{left:leftList[tagIndex].left,width:leftList[tagIndex].width}" ></div>
                </div>
                <div 
                    class="item_wrapper flex flex-col flex-jfs flex-ac" 
                >
                    <div class="item flex flex-jsb flex-ac" v-for="(item) in taskDetail.quest" :key="item.id" >
                        <div class="flex flex-ac" >
                            <img class="item_img" :src="iconHandler(item)" />
                            <span class="item_txt" >{{item.task_name}}</span>
                            <span class="item_num" v-if="item.total_num" > {{item.complete_num}}/{{item.total_num}}</span>
                        </div>
                        <div class="flex flex-jc flex-ac" >
                            <span class="item_num" >+{{item.point}}</span>
                            <img class="item_icon" src="@/assets/medalOrange.svg" />
                            <img class="item_check" :src="item.is_complete?require('@/assets/taskCheckActive.svg'):require('@/assets/taskCheck.svg')" />
                        </div>
                        <!-- <div class="item_btn flex flex-jc flex-ac" :style="{
                            background:item.is_complete?'#CCCCCC':'linear-gradient(90deg, #FE5900 0%, #C51A24 100%)'
                        }" >
                            <span style="transform:skew(9deg)" >{{item.is_complete?'Complete':'Uncompleted'}}</span>
                        </div> -->
                    </div>
                    <i :style="{opacity:loading?1:0}" class="el-icon-loading"  style="color:#FFFFFF;font-size:2rem;margin-top:1rem;" ></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props:{
        showFlag:{
            type:Boolean,
            required:true,
        }
    },
    data(){
        return {
            tagList:[
                {
                    name:'New User Task',
                    key:'new_user'
                },
                {
                   name:'Daily Task' ,
                   key:'day'
                },
                {
                   name:'Weekly Task' ,
                   key:'week'
                },
                {
                   name:'Extra Task' ,
                   key:'advanced'
                },
            ],
            tagIndex:0,
            typeList:[
                {
                    title:'User Contribution Point Today',
                    key:'today_user_point',
                    value:537464,
                    icon:require('@/assets/medal.svg'),
                },
                {
                    title:'Estimated Earning Today',
                    key:'today_estimated_eaming',
                    value:519364,
                    // icon:require('@/assets/promotion.svg'),
                    icon: require('@/assets/SPK.png'),
                },
            ],
            leftList:[{left:'.69rem',width:'5.19rem'}],
            taskDetail:{
                'today_estimated_eaming':0,
                'today_user_point':0,
                'is_has_new_user_task':true,
                quest:[]
            },
            needLoad: true,
        }
    },
    created(){
        this.getList()
    },
    mounted(){
        this.$nextTick(()=>{
            console.log('查看refs---',this.$refs)
            let wrapper=this.$refs.tagWrapper.getBoundingClientRect();
            this.leftList=[]
            this.$refs.tag.forEach(item=>{
                let rect=item.getBoundingClientRect();
                // console.log(rect.left,wrapper.left);
                this.leftList.push({width:rect.width+'px',left:(rect.left-wrapper.left)+'px'})
            })
            window.addEventListener('resize',this.resizeHandler);
        })
        // this.$refs.boxBg.addEventListener('scroll',function(e){
        //     e.stopPropagation();
        //     console.log('滚动')
        // })
    },
    beforeDestroy(){
        window.removeEventListener('resize',this.resizeHandler)
    },
    methods:{
        numFilter(num){
            num = Number(num)
            if( num > 1000000 )return parseInt( num / 10 ).toLocaleString()+'+';
            return  parseInt( num ).toLocaleString();
        },
        resizeHandler(){
            console.log('tag重定位')
            let wrapper=this.$refs.tagWrapper.getBoundingClientRect();
            let cacheList=[]
            this.$refs.tag.forEach(item=>{
                let rect=item.getBoundingClientRect();
                // console.log(rect.left,wrapper.left);
                cacheList.push({width:rect.width+'px',left:(rect.left-wrapper.left)+'px'})
            })
            this.$set(this,'leftList',cacheList)
            this.$forceUpdate()
        },
        async getList(load = true,refresh = false ){
            if(this.loading)return
            if(!load) this.needLoad = false ;
            if(refresh) {
                this.tagIndex = 0;
                if(this.tagList.length != 4){
                    this.tagList.unshift({
                        name:'New User Task',
                        key:'new_user'
                    });
                    this.$nextTick(()=>{this.resizeHandler()})
                }
            }
            if(this.needLoad)this.loading=true
            try{
                if(this.needLoad)this.taskDetail.quest=[]
                let res =await this.$request({
                    path:'common/getTask',
                    data:{
                        task_type:this.tagList[this.tagIndex].key
                    }
                })
               this.taskDetail=res.data
               this.typeList.forEach((item,index)=>{
                this.typeList[index].value=this.taskDetail[item.key];
               })
               if(!this.taskDetail.is_has_new_user_task  && this.tagList[0].key == 'new_user' ){
                this.tagList.shift();
                this.$nextTick(()=>{this.resizeHandler()})
                if(this.tagIndex) this.tagIndex--;
                this.loading=false
                this.needLoad=true
                return this.getList(load);
               }
            }catch(err){
                console.log('err-',err)
            }
            this.loading=false
            this.needLoad=true
        },
        changeFlag(e,state=true){
            this.$emit('change',state)
            // this.showFlag=state
        },
        tagChange(index){
            if(this.loading)return 
            this.tagIndex=index
            this.getList()
        },
        iconHandler({task_key,unit_key}){
            if(task_key=='watch_video'){
                if(unit_key == 'time')return require('@/assets/task_vedio.svg')
                else return require('@/assets/task_view.svg')
            }

            switch(task_key){
                case 'sign_in': return require('@/assets/task_sign.svg');
                case 'share_post': return require('@/assets/task_share.svg');
                case 'comment': return require('@/assets/task_comment.svg');
                case 'buy_spk_nft': return require('@/assets/task_nft.svg');
                case 'invite': return require('@/assets/task_invite.svg');
                case 'invite_buy_nft': return require('@/assets/task_buy.svg');
            }
        },
        jumpHistory(){
            this.$router.push({ name: 'ExchangeHistory' });
            this.$emit('change', false);
        }
    },
    computed:{
        ...mapGetters(["isMoblie"]),
        popStyle(){
            return { 
                transform:`scale(${this.showFlag? 1:0})`,
                width:this.showFlag?'55.63rem':0,
                height:this.showFlag?'54.44rem':0,
            }
        },
    },
    watch:{
        showFlag(nval){
            console.log('触发了')
            if(nval){
                setTimeout(() => {
                    let wrapper=this.$refs.tagWrapper.getBoundingClientRect();
                    this.leftList=[]
                    this.$refs.tag.forEach(item=>{
                        let rect=item.getBoundingClientRect();
                        // console.log(rect.left,wrapper.left);
                        this.leftList.push({width:rect.width+'px',left:(rect.left-wrapper.left)+'px'})
                    })
                    window.addEventListener('resize',this.resizeHandler);
                }, 350); 
            }
        }
    }
}
</script>

<style lang="scss" scoped >
    .box{
        position:fixed;
        left:0;
        top:0;
        z-index:1000;
        background: rgba(255,255,255,.06);
        backdrop-filter: blur(10px);
        height:100vh;
        width:100vw;
        overflow: hidden;
        transition: transform .3s ,opacity .3s;
         &_img{
            height:8.69rem;
            width:8.63rem;
            &_wrapper{
                cursor: pointer;
                position: absolute;
                top:19rem;
                right:5rem;
            }
        }
        &_content{
            width:55.63rem;
            height:54.44rem;
            box-sizing: border-box;
            padding:2.03rem{
                top:2.81rem;
                bottom:1.5rem;
            }
            background:linear-gradient(135deg,transparent 0%,transparent 1%,rgba(0,0,0,.7) 1%,rgba(0,0,0,.7) 97.5%,transparent 97.5%);
            position:relative;
            transition: all .35s ease;
            &::before{
                content:'';
                position:absolute;
                z-index:1000;
                top:0;
                left:0;
                width:2rem;
                height:2rem;
                background:no-repeat center/100% url(@/assets/boxTop.svg);
            }

            &::after{
                content:'';
                position:absolute;
                z-index:1000;
                bottom:0;
                right:0;
                width:1.6rem;
                height:1.6rem;
                background:no-repeat center/100% url(@/assets/boxBottom.svg);
            }

            &_shrink{
                position: absolute;
                top:1.11rem;
                right:1.11rem;
                width:2.25rem;
                height:2.25rem;
                cursor: pointer;
            }

            &_title{
                display: inline-block;
                width:35.13rem;
                text-align: center;
                color:#FFFFFF;
                font-size:1rem;
                line-height:1.25rem;
                font-family: SVN-Gilroy;
                margin-bottom:1.25rem;
            }
            &_data{
                width:40rem;
                margin-bottom:1.81rem;
            }
        }
    }
    .data{
        background:no-repeat center/100% url(@/assets/statistics-bg.png);
        height:14.63rem;
        width:17.5rem;
        margin-bottom:.75rem;
        &_icon{
            height:3.13rem;
            width:3.13rem;
            margin-top:2.75rem;
            margin-bottom:.94rem;
        }
        &_num{
            color:#FFFFFF;
            font-size:2rem;
            line-height:2.56rem;
            letter-spacing: .1rem;
            margin-bottom:.44rem;
            font-family: SVN-GilroyXBold;
            font-weight: bold;
        }
        &_title{
            color:#fff;
            font-size:1.13rem;
            line-height:1.44rem;
            font-family: SVN-Gilroy;
            display: inline-block;
            width:12.5rem;
            text-align: center;
        }
        &_subtitle{
            color:#FE5900;
            font-size:1rem;
            line-height:1.25rem;
            font-family: SVN-GilroyMedium
        }
    }
    .tag{
        width:47.5rem;
        padding:1rem 0;
        position:relative;
        border-top:.06rem solid rgba(255,255,255,.1);
        border-bottom:.06rem solid rgba(255,255,255,.1);
        &_item{
            font-size:1.38rem;
            line-height: 1.81rem;
            font-family: SVN-GilroySemiBold;
            margin-right:3.13rem;
            color:#FFFFFF;
            cursor: pointer;
        }
        &_bar{
            position:absolute;
            width:5.19rem;
            height:.19rem;
            background: linear-gradient(90deg, #FE5900 0%, #C51A24 100%);
            transform: skew(-30deg);
            bottom:0;
            left:.69rem;
            z-index:2;
            transition:all .15s;
        }
        &_history{
            color: #FFFFFF;
            font-size: 1rem;
            font-family: SVN-Gilroy;
            padding: .6rem 0.7rem;
            background: linear-gradient(90deg, #FE5900 0%, #C51A24 100%);
            border-radius: 0.38rem;
            border: 0.09rem solid rgba(0,0,0,0.31);
            cursor: pointer;
        }
    }
    .item{
        width:47.5rem;
        height:4.06rem;
        margin-top:.94rem;
        border-radius: .5rem;
        border:.06rem solid rgba(255,255,255,.1);
        background: rgba(63, 63, 63, 0.25);
        padding:.63rem .94rem;
        box-sizing: border-box;
        &_wrapper{
            height:24.6rem;
            overflow-x: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
            border-radius: 10px;
            width: 0px;
            display: none;
            }

            &::-webkit-scrollbar-track {
            border-radius: 0;
            background: rgba(0, 0, 0, 0.8);
            }

            &::-webkit-scrollbar-thumb {
            background: rbga(0, 0, 0, 0.2);
            }

            &::-webkit-scrollbar:vertical {
            display: block;
            }
        }
        &_img{
            height:2.81rem;
            width:2.81rem;
            margin-right:.63rem;
        }
        &_icon{
            height:1.44rem;
            width:1.44rem;
            margin-right:1.56rem;
        }
        &_txt{
            font-size: 1.13rem;
            font-family: SVN-GilroyMedium;
            color: #FFFFFF;
            line-height: 1.44rem;
            letter-spacing: .03rem;
            display: inline-block;
            text-align: left;
            margin-right: .94rem;
        }
        &_num{
            color:#FE5900;
            font-size:1.13rem;
            line-height:1.44rem;
            letter-spacing: .03rem;
            font-family: SVN-GilroyBold;
            margin-right:.31rem;
        }
        &_check{
            height:1.88rem;
            width:1.88rem;
        }
        &_btn{
            background: linear-gradient(90deg, #FE5900 0%, #C51A24 100%);
            border-radius: 0.5rem;
            border: 0.09rem solid rgba(0,0,0,0.31);
            width: 10.13rem;
            height: 2.75rem;
            color:#FFFFFF;
            font-size:1rem;
            line-height:1.25rem;
            font-family: SVN-GilroyMedium;
            transform: skew(-9deg);
        }
    }
</style>