<template>
  <div class="box_wrapper flex flex-jc flex-ac" :style="[wrapperStyle]">
    <div class="box flex flex-col flex-jfs flex-afs" :style="[boxSytle]">
      <img
        v-show="popType !== 2"
        class="box_close"
        src="@/assets/close.svg"
        @click="close"
      />
      <img class="box_logo" src="@/assets/logLogo.png" />
      <span v-show="popType !== 2" class="box_title">{{
        popType == 1
          ? 'Welcome to AICEAN'
          : popType == 3
          ? 'Reset password'
          : 'Login with Email'
      }}</span>
      <template v-if="!popType">
        <span class="box_subtitle">Please select sign-in method</span>

        <div
          class="box_item_wrapper flex flex-col flex-jfs flex-ac"
          :style="{ height: `${activeIndex == 2 ? 23.88 : 3.94}rem` }"
          @click="clickItem(2)"
        >
          <div class="box_item flex flex-jfs flex-ac">
            Login/Register with Email
          </div>
          <div class="box_hide flex flex-col flex-jc flex-ac" @click.stop="">
            <input
              class="box_item_input"
              type="text"
              v-model="email"
              placeholder="Email"
            />
            <input
              class="box_item_input"
              type="password"
              v-model="password"
              placeholder="Password"
            />
            <div class="box_item_btn flex flex-jc flex-ac" @click="emailLog">
              Login
            </div>
            <div class="flex flex-jsb w-full">
              <span class="box_item_txt" @click.stop="popType = 1"
                >Sign up With Email</span
              >
              <span class="box_item_txt" @click.stop="popType = 3"
                >Forget Passwordl?</span
              >
            </div>
          </div>
        </div>

        <div
          v-loading="loadingList[0]"
          class="box_item_wrapper box_item flex flex-jfs flex-ac"
          @click="walletHandler"
        >
          Connect Wallet
        </div>
        <div class="bottom flex flex-jfs flex-ac">
          <div
            class="bottom_check_wrapper flex flex-jc flex-ac"
            @click.stop="agreeFlag = !agreeFlag"
          >
            <img
              v-show="agreeFlag"
              class="bottom_check"
              src="@/assets/complete.svg"
            />
          </div>
          <span class="bottom_txt">I agree to the</span>
          <span class="bottom_a" @click.stop="jumpPolicy"
            >Terms & Conditions</span
          >
        </div>
      </template>
      <div v-else-if="popType == 1" class="elog flex flex-col flex-jfs flex-ac">
        <input
          class="box_item_input"
          type="text"
          v-model="email"
          placeholder="Email"
        />
        <div
          class="flex flex-jsb flex-ac"
          style="width: 87%; margin-bottom: 1.56rem"
        >
          <input
            class="box_item_input"
            style="margin-bottom: 0"
            type="number"
            v-model="code"
            max="10"
            placeholder="code"
          />
          <div
            class="elog_otp flex flex-jc flex-ac"
            @click.stop="sendCode()"
            v-loading="sendLoading"
            element-loading-background="rgba(0,0,0,.6)"
          >
            {{ timer ? tick : 'Send Code' }}
          </div>
        </div>
        <input
          class="box_item_input"
          type="password"
          v-model="password"
          placeholder="Password"
        />
        <div
          class="box_item_btn flex flex-jc flex-ac"
          @click="emailRegister"
          v-loading="regLoading"
          element-loading-background="rgba(0,0,0,.6)"
        >
          Sign Up
        </div>
      </div>
      <div v-else-if="popType == 2" class="sign flex flex-col flex-jc flex-ac">
        <img class="sign_img" src="@/assets/default-avatar.png" />
        <span class="sign_title">Welcome to AICEAN</span>
        <span class="sign_desc"
          >By connecting your wallet and using AICEAN, you agree to our Terms
          and Conditions</span
        >
        <div class="sign_bottom">
          <div
            v-loading="signFlag"
            element-loading-background="rgba(0,0,0,.6)"
            class="sign_bottom_btn flex flex-jc flex-ac"
            @click.stop="cancelHandler"
          >
            Cancel
          </div>
          <div
            v-loading="signFlag"
            element-loading-background="rgba(0,0,0,.6)"
            class="sign_bottom_btn flex flex-jc flex-ac"
            @click.stop="loginHandler"
          >
            Accept
          </div>
        </div>
      </div>
      <div v-else-if="popType == 3" class="elog flex flex-col flex-jfs flex-ac">
        <input
          class="box_item_input"
          type="text"
          v-model="email"
          placeholder="Email"
        />
        <div
          class="flex flex-jsb flex-ac"
          style="width: 87%; margin-bottom: 1.56rem"
        >
          <input
            class="box_item_input"
            style="margin-bottom: 0"
            type="number"
            v-model="code"
            max="10"
            placeholder="code"
          />
          <div
            class="elog_otp flex flex-jc flex-ac"
            @click.stop="sendCode('forget_password')"
            v-loading="sendLoading"
            element-loading-background="rgba(0,0,0,.6)"
          >
            {{ timer ? tick : 'Send Code' }}
          </div>
        </div>
        <input
          class="box_item_input"
          type="password"
          v-model="password"
          placeholder="New Password"
        />
        <div
          class="box_item_btn flex flex-jc flex-ac"
          @click="forgetPassword"
          v-loading="regLoading"
          element-loading-background="rgba(0,0,0,.6)"
        >
          Reset
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { WalletEthers } from '@/utils/walletconnect2'
import { getAccount, watchAccount, disconnect } from '@wagmi/core'
import walletSign from '@/components/walletSign/index.js'
import { nextTick } from 'vue'
export default {
  name: 'Login',
  inject: ['reload'],
  data() {
    return {
      activeIndex: -1,
      email: '',
      password: '',
      code: '',
      register_type: 1,
      loadingList: [false, false, false],
      popType: 0,
      typeTimer: null,
      timer: null,
      tick: 60,
      sendLoading: false,
      regLoading: false,
      agreeFlag: true,
      unWatch: null,
      signFlag: false,
    }
  },
  beforeDestroy() {
    this.typeTimer && clearTimeout(this.typeTimer)
    this.timer && clearInterval(this.timer)
    this.unWatch && this.unWatch()
  },
  methods: {
    ...mapActions('user', ['login']),
    async getProvider() {
      const BitKeepProvider = window.bitkeep && window.bitkeep.ethereum

      await BitKeepProvider.request({ method: 'eth_requestAccounts' })

      BitKeepProvider.removeAllListeners()
      BitKeepProvider.on('accountsChanged', async (accounts) => {
        console.log('accounts changed')
      })
      BitKeepProvider.on('chainChanged', async (chainId) => {
        console.log('chainId changed')
      })

      //MetaMask used
      const MetaMaskProvider = window.ethereum
      //BitKeepProvider.removeAllListeners();
      MetaMaskProvider.request({ method: 'eth_requestAccounts' })
      MetaMaskProvider.removeAllListeners()
      MetaMaskProvider.on('accountsChanged', async (accounts) => {
        console.log('accounts changed')
      })
      MetaMaskProvider.on('chainChanged', async (chainId) => {
        console.log('chainId changed')
      })
    },
    close() {
      // this.$message({message:'测试'})
      this.email = ''
      this.password = ''
      this.code = ''
      this.activeIndex = -1
      this.timer && clearTimeout(this.timer)
      if (this.popType !== 0) {
        if (this.typeTimer) {
          clearTimeout(this.typeTimer)
          this.typeTimer = null
        }
        this.typeTimer = setTimeout(() => {
          this.typeTimer = null
          this.popType = 0
        }, 300)
      }
      nextTick(() => {
        this.$store.commit('common/LOG_STATE', false)
      })
    },
    clickItem(index) {
      this.activeIndex = this.activeIndex == index ? -1 : index
    },
    validateEmail(email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    async emailLog() {
      try {
        if (!this.email) throw 'Please Enter your Email'
        else if (!this.password) throw 'Please Enter Password'
        else if (!this.agreeFlag) throw 'Please Agree Policy'
        if (!this.validateEmail(this.email)) {
          return this.$message({
            message: 'Please enter the correct email address',
            type: 'warning',
            showClose: true,
            customClass: 'myMessage',
          })
        }
        await this.login({
          type: 2,
          data: { email: this.email, password: this.password },
        })
        this.$store.commit('common/LOG_STATE', false)
        this.$message({
          message: 'Login Success',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        console.log(this.$router.path)
        this.reload()
        // if( !this.chain ) await this.bindHandler();
      } catch (err) {
        console.log('邮箱登陆失败---', err)
        // this.$message({
        //   message: err,
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
      }
    },
    async walletHandler() {
      // await this.walletObj.connect();
      if (!this.agreeFlag)
        return this.$message({
          message: 'Please Agree Policy',
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
      const { isConnected } = getAccount()
      if (!isConnected) {
        await WalletEthers.modal.openModal()
        this.unWatch && this.unWatch()
        this.unWatch = watchAccount((account) => {
          console.log('有地址变化---', account)
          alert('有地址变化---', account)
          if (account.address && account.isConnected) {
            this.loginHandler()
            this.unWatch()
          }
        })
      } else this.loginHandler()
    },
    async loginHandler() {
      try {
        const { address, isConnected } = getAccount()
        if (!isConnected) throw 'please connect wallet'
        this.signFlag = true
        const signature = await this.walletObj.sign(WalletEthers.message)
        await this.$store.dispatch('user/login', {
          type: 1,
          data: {
            chain_address: address,
            signature,
            message: WalletEthers.message,
            invite_code: this.$store.state.common.inviteCode,
          },
        })
        this.$store.commit('common/LOG_STATE', false)
        this.$message({
          message: 'Login Success',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        WalletEthers.reload()
      } catch (err) {
        console.log('err-', err)
        this.$message({
          message: err?.reason || err?.data?.message || err?.message || err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
        await disconnect()
        this.$store.dispatch('user/logout')
      }
      this.signFlag = false
    },
    async sendCode(type = 'register') {
      try {
        if (this.sendLoading || this.timer) return
        if (!this.validateEmail(this.email)) {
          return this.$message({
            message: 'Please enter the correct email address',
            type: 'warning',
            showClose: true,
            customClass: 'myMessage',
          })
        }
        this.sendLoading = true
        // if(!this.email.match(/^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_-]+).([a-zA-Z0-9_-]+)(.[a-zA-Z0-9_-]+)*$/)) throw '邮箱格式不正确';
        await this.$request({
          path: 'user/sendCode',
          data: {
            email: this.email,
            type,
          },
        })
        this.timer = setInterval(() => {
          if (!this.tick) {
            clearInterval(this.timer)
            this.timer = null
            return (this.tick = 60)
          }
          this.tick--
        }, 1000)
      } catch (err) {
        console.log('发送失败---', err)
        // this.$message({
        //   message: err,
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
      }
      this.sendLoading = false
    },
    async emailRegister() {
      try {
        if (!this.email) throw 'Please Enter your Email'
        else if (!this.code) throw 'Please Enter Code'
        else if (!this.password) throw 'Please Enter Password'
        if (!this.validateEmail(this.email)) {
          return this.$message({
            message: 'Please enter the correct email address',
            type: 'warning',
            showClose: true,
            customClass: 'myMessage',
          })
        }
        this.regLoading = true
        await this.login({
          type: 3,
          data: {
            email: this.email,
            code: this.code,
            password: this.password,
            invite_code: this.inviteCode,
            register_type: this.register_type,
          },
        })
        this.$message({
          message: 'Register Success',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        this.$store.commit('common/LOG_STATE', false)
        this.reload()
        // if( !this.chain ) await this.bindHandler();
      } catch (err) {
        console.log('注册时失败---', err)
        // this.$message({
        //   message: err,
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
      }
      this.regLoading = false
    },
    async forgetPassword() {
      try {
        if (!this.email) throw 'Please Enter your Email'
        else if (!this.code) throw 'Please Enter Code'
        else if (!this.password) throw 'Please Enter Password'
        this.regLoading = true
        await this.$request({
          path: 'user/forgetPassword',
          data: {
            email: this.email,
            code: this.code,
            password: this.password,
            invite_code: this.inviteCode,
          },
        })
        this.$message({
          message: 'Password reset successful',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        this.$store.commit('common/LOG_STATE', false)
        this.reload()
        // if( !this.chain ) await this.bindHandler();
      } catch (err) {
        console.log('注册时失败---', err)
        this.$message({
          message: err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
      }
      this.regLoading = false
    },
    jumpPolicy() {
      this.$router.push({ name: 'Policy' })
      this.close()
    },
    cancelHandler() {
      disconnect()
      this.close()
    },
    async bindHandler() {
      try {
        const { isConnected } = getAccount()
        if (!isConnected) {
          await WalletEthers.modal.openModal()
          this.unWatch = watchAccount(async (account) => {
            if (account.address && account.isConnected) walletSign.install({})
          })
        } else walletSign.install({})
      } catch (err) {
        console.log('err', err)
        this.$message({
          message:
            err?.reason ||
            err?.data?.message ||
            err?.message ||
            err?.msb ||
            err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
        if (this.unWatch) {
          this.unWatch()
          this.unWatch = null
        }
        await disconnect()
      }
    },
  },
  computed: {
    ...mapGetters([
      'inviteCode',
      'showLog',
      'metaObj',
      'walletObj',
      'chain',
      'Email',
    ]),
    wrapperStyle() {
      return {
        opacity: this.showLog ? 1 : 0,
        backdropFilter: `blur(${this.show ? 14 : 1}px)`,
        PointerEvents: this.show ? 'auto' : 'none',
        zIndex: this.showLog ? 1002 : -100,
      }
    },
    boxSytle() {
      return {
        transform: `scale(${this.showLog ? 1 : 0.1})`,
      }
    },
  },
  watch: {
    showLog(nval) {
      console.log(!nval)
      if (nval) {
        this.email = ''
        this.password = ''
        this.code = ''
        this.activeIndex = -1
        this.timer && clearTimeout(this.timer)
        this.timer = null
        this.tick = 60
        if (this.popType !== 0) {
          if (this.typeTimer) {
            clearTimeout(this.typeTimer)
            this.typeTimer = null
          }
          this.typeTimer = setTimeout(() => {
            this.typeTimer = null
            this.popType = 0
          }, 300)
        }
      }
      console.log(this.Email != '')
      if (this.Email != '') {
        this.email = this.Email
        this.clickItem(2)
        this.register_type = 2
        this.popType = 1
        nextTick(() => {
          this.sendCode()
        })
        this.$store.commit('common/SET_Email', '')
      } else {
        this.register_type = 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}
.box {
  width: 30.63rem;
  min-height: 35.63rem;
  box-sizing: border-box;
  padding: 2.03rem {
    top: 2.81rem;
    bottom: 0rem;
  }
  background: linear-gradient(
    135deg,
    transparent 0%,
    transparent 1%,
    rgba(0, 0, 0, 0.7) 1%,
    rgba(0, 0, 0, 0.7) 97.5%,
    transparent 97.5%
  );
  position: relative;
  transition: all 0.2s ease;
  &::before {
    content: '';
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    background: no-repeat center/100% url(@/assets/boxTop.svg);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1000;
    bottom: 0;
    right: 0;
    width: 1.6rem;
    height: 1.6rem;
    background: no-repeat center/100% url(@/assets/boxBottom.svg);
  }

  &_wrapper {
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.06) !important;
    backdrop-filter: blur(0.875rem) !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    transition: all 0.2s ease;
    // pointer-events: none;
  }

  &_close {
    height: 2.25rem;
    width: 2.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }

  &_logo {
    width: 9.13rem;
    height: 2rem;
    margin: 0 {
      bottom: 1.25rem;
    }
  }

  &_title {
    color: #ffffff;
    font-size: 1.88rem;
    line-height: 2.38rem;
    font-family: SVN-GilroyBold;
    margin-bottom: 0.94rem;
  }

  &_subtitle {
    font-size: 1.13rem;
    line-height: 1.44rem;
    font-family: SVN-GilroyMedium;
    color: var(--fcolor);
    display: block;
    width: 26.56rem;
    padding-bottom: 2.19rem;
    border-bottom: 0.06rem solid rgba(255, 255, 255, 0.1);
    text-align: left;
  }

  &_item {
    width: 26.56rem;
    height: 3.94rem;
    background: no-repeat center/100% url(@/assets/metamask.png);
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1.56rem;
    font-family: SVN-GilroyMedium;
    box-sizing: border-box;
    padding: 1.19rem 0;
    padding-left: 4.56rem;

    &_wrapper {
      width: 26.56rem;
      min-height: 3.94rem;
      margin-top: 2.19rem;
      overflow: hidden;
      cursor: pointer;
      transition: height 0.25s;
      z-index: 5;
      &:nth-of-type(1) > div:nth-of-type(1) {
        background: no-repeat center/100% url(@/assets/email.png);
      }
      &:nth-of-type(2) {
        background: no-repeat center/100% url(@/assets/walletconnect.png);
      }
    }

    &_input {
      width: 23.13rem;
      height: 3.44rem;
      background: transparent;
      border: 0.06rem solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      padding: 1rem 0.94rem;
      color: #ffffff;
      font-size: 1.13rem;
      line-height: 1.44rem;
      font-family: SVN-Gilroy;
      border-radius: 0.25rem;
      outline: 0rem solid transparent;
      transition: outline 0.25s;
      &:focus {
        outline: 0.06rem solid #ffffff;
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.25);
        font-size: 1.13rem;
        line-height: 1.44rem;
        font-family: SVN-Gilroy;
      }

      &:nth-of-type(1) {
        margin-bottom: 1.56rem;
      }
    }

    &_btn {
      width: 23.13rem;
      height: 3.44rem;
      background: no-repeat center/100% url(@/assets/btn.svg);
      color: #ffffff;
      font-size: 1.25rem;
      line-height: 1.56rem;
      font-family: SVN-GilroyMedium;
      cursor: pointer;
      margin: 0 {
        top: 1.88rem;
        bottom: 0.81rem;
      }
    }

    &_txt {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 1.44rem;
      text-decoration: underline;
      font-size: 1.25rem;
      line-height: 1.25rem;
      color: #fe5900;
      font-family: SVN-Gilroy;
      cursor: pointer;
    }
  }

  &_hide {
    width: 26.56rem;
    box-sizing: border-box;
    height: calc(23.88rem - 3.94rem);
    border: 0.13rem solid rgba(255, 255, 255, 0.15);
    border-radius: 0.25rem;
    margin-top: -0.5rem;
    z-index: -1;
    cursor: default;
    &_code {
      height: 16rem;
      width: 16rem;
      background: rgba(0, 0, 0, 1);
      border: 1rem solid #ffffff;
      margin: 0.94rem 0;
      font-size: 0;

      & > * {
        object-fit: contain;
      }
    }
  }
}

.bottom {
  padding: 1.25rem 0;
  width: 100%;
  border-top: 0.06rem solid rgba(255, 255, 255, 0.1);
  margin-top: 6rem;
  align-self: end;
  &_check {
    height: 1.13rem;
    width: 1.13rem;
    &_wrapper {
      border-radius: 100%;
      margin-right: 0.63rem;
      height: 1.13rem;
      width: 1.13rem;
      border: 0.06rem solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }
  &_txt {
    color: white;
    font-size: 1rem;
    line-height: 1.25rem;
    font-family: SVN-GilroyMedium;
    margin-right: 0.31rem;
  }
  &_a {
    text-decoration: underline;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #fe5900;
    font-family: SVN-Gilroy;
    cursor: pointer;
  }
}

.elog {
  width: 100%;
  padding-top: 2.81rem;
  margin-top: 1.56rem;
  border-top: 0.06rem solid rgba(255, 255, 255, 0.1);

  &_otp {
    width: 12.5rem;
    height: 3.44rem;
    background: linear-gradient(to right, #fe5900, #c51a24);
    border-radius: 0.25rem;
    box-sizing: border-box;
    border: 0.09rem solid rgba(0, 0, 0, 0.31);
    color: #ffffff;
    font-size: 1.13rem;
    line-height: 1.44rem;
    font-family: SVN-GilroyMedium;
    margin-left: 0.75rem;
    cursor: pointer;
  }
}

.sign {
  width: 100%;
  &_img {
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    margin-bottom: 1.5rem;
  }
  &_title {
    color: #ffffff;
    font-size: 2.38rem;
    font-family: SVN-GilroyBold;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  &_desc {
    color: #ffffff;
    font-size: 1.2rem;
    font-family: SVN-GilroyMedium;
  }
  &_bottom {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    margin-top: 6rem;
    &_btn {
      height: 3.9rem;
      width: 100%;
      border-radius: 0.25rem;
      font-size: 1.25rem;
      font-family: SVN-GilroyBold;
      letter-spacing: 0.06rem;
      cursor: pointer;
      font-weight: bold;
      &:nth-of-type(1) {
        background: rgba(255, 255, 255, 0.3);
        color: black;
      }
      &:nth-of-type(2) {
        background: linear-gradient(to right, #fe5900, #c51a24);
        color: white;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
