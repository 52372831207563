import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { randomString } from '@/utils/common'
import md5 from 'js-md5'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000,
})

// console.log('------',process.env.VUE_APP_BASE_URL,service.baseURL)

service.interceptors.request.use(
  (config) => {
    // console.log(config)
    if (store.getters.token)
      config.headers['Authorization'] = 'Bearer ' + store.getters.token
    config.headers['Cache-Control'] = 'no-cache'
    let timestamp = new Date().getTime()
    let nonce = randomString(8)
    let sign = md5(
      timestamp +
        nonce +
        'vnxAdWMwRQvDROADK5RmgWO6Wngl36YZzNQdIbPEV90ZyUwYWMHn6RgiJ8aqUn4Z'
    )
    let additional = {
      timestamp,
      nonce,
      sign,
      device_id: store.getters.deviceId,
    }
    if (config.method == 'get') {
      if (!config.params) config.params = additional
      else config.params = { ...config.params, ...additional }
    } else {
      if (!config.data) config.data = additional
      else config.data = { ...config.data, ...additional }
    }
    // console.log(config)
    return config
  },
  (error) => {
    console.log('出错---', error)
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    // console.log('response---',response)
    const res = response.data
    if (res.code == 0) {
      return response.data
    } else {
      if (res.code == 401) {
        store.commit('common/LOG_STATE', true)
        store.dispatch('user/logout')
      } else {
        Message({
          message: res?.msg || 'request error',
          type: 'error',
          duration: 5 * 1000,
          customClass: 'myMessage',
          showClose: true,
        })
      }
      return Promise.reject(res)
    }
  },
  (error) => {
    console.log('响应出错---', error)
    Message({
      message: error || 'response Error',
      type: 'error',
      duration: 5 * 1000,
      customClass: 'myMessage',
    })
    return Promise.reject(error)
  }
)

export default service
