module.exports = {
  login: {
    url: '/api/frontend/login',
    method: 'POST',
  },
  emailLogin: {
    url: '/api/frontend/user/emailLogin',
    method: 'POST',
  },
  walletLogin: {
    url: '/api/frontend/user/walletLogin',
    method: 'POST',
  },
  logout: {
    url: '/api/frontend/logout',
    method: 'POST',
  },
  getInfo: {
    url: '/api/frontend/user/info',
    method: 'GET',
  },
  changeInfo: {
    url: '/api/frontend/user/infoStore',
    method: 'POST',
  },
  sendCode: {
    url: '/api/frontend/user/sendEmailCode',
    method: 'POST',
  },
  changeEmail: {
    url: '/api/frontend/user/emailStore',
    method: 'POST',
  },
  getWallet: {
    url: '/api/frontend/user/wallets',
    method: 'GET',
  },
  getWalletDetail: {
    url: '/api/frontend/user/walletDetail',
    method: 'GET',
  },
  getVedioList: {
    url: '/api/frontend/userPost/userPostList',
    method: 'GET',
  },
  getVedioDetail: {
    url: '/api/frontend/userPost/userPostDetail',
    method: 'GET',
  },
  getVedioStatistcs: {
    url: '/api/frontend/userPost/dataCount',
    method: 'GET',
  },
  getPromoteTaskList: {
    url: '/api/frontend/inviteTask/list',
    method: 'GET',
  },
  claimPromoteTask: {
    url: '/api/frontend/inviteTask/claim',
    method: 'POST',
  },
  getPromoteUserList: {
    url: '/api/frontend/user/invitationLog',
    method: 'GET',
  },
  getUserTaskList: {
    url: '/api/frontend/task/userTaskDetail',
    method: 'GET',
  },
  getUserUnfinishNum: {
    url: '/api/frontend/task/unfinishTaskNum',
    method: 'GET',
  },
  getRewardDetail: {
    url: '/api/frontend/userReward/detail',
    method: 'GET',
  },
  getEquipList: {
    url: '/api/frontend/userReward/userEquipment',
    method: 'GET',
  },
  setNFTEquip: {
    url: '/api/frontend/userReward/equipment',
    method: 'POST',
  },
  cancelNFTEquip: {
    url: '/api/frontend/userReward/cancelEquipment',
    method: 'POST',
  },
  getRewardLogList: {
    url: '/api/frontend/userReward/userRewardLog',
    method: 'GET',
  },
  getRewardLogDetailList: {
    url: '/api/frontend/userReward/userRewardLogDetail',
    method: 'GET',
  },
  withDrawWallet: {
    url: '/api/frontend/user/walletCashOut',
    method: 'POST',
  },
  getCollectList: {
    url: '/api/frontend/post/collectLog',
    method: 'GET',
  },
  getFollowList: {
    url: '/api/frontend/user/followLog',
    method: 'GET',
  },
  shareVedio: {
    url: '/api/frontend/post/share',
    method: 'POST',
  },
  getCashOutLog: {
    url: '/api/frontend/user/cashOutLog',
    method: 'GET',
  },
  getWalletLog: {
    url: '/api/frontend/user/chainTxLog',
    method: 'GET',
  },
  getExchangeLog: {
    url: '/api/frontend/user/walletLog',
    method: 'GET',
  },
  deleteComment: {
    url: '/api/frontend/post/commentDel',
    method: 'POST',
  },
  getAccessToken: {
    url: '/api/frontend/getAccessToken',
    method: 'GET',
  },
  statisticsData: {
    url: '/api/frontend/user/profile',
    method: 'GET',
  },
  vdeioHistory: {
    url: '/api/frontend/post/viewLog',
    method: 'GET',
  },
  emailRegister: {
    url: '/api/frontend/user/emailRegister',
    method: 'POST',
  },
  bindWallet: {
    url: '/api/frontend/user/walletBind',
    method: 'POST',
  },
  getPointHistory: {
    url: '/api/frontend/task/userTaskPointLog',
    method: 'GET',
  },
  getBanners: {
    url: '/api/frontend/banners',
    method: 'GET',
  },
  forgetPassword: {
    url: '/api/frontend/user/forgetPassword',
    method: 'POST',
  },
}
