<template>
  <div
    ref="app"
    id="app"
    class="flex flex-col flex-jfs flex-ac"
    :style="{ minHeight: `${wdHeight}px`, width: `${wdWidth}px` }"
  >
    <Login />
    <!-- <Introduction v-model="introductionFlag" :showFlag="introductionFlag" /> -->
    <my-header
      ref="header"
      :style="{ zIndex: hasPop ? 1 : 1000 }"
      v-show="isshowheader"
    />
    <!-- <Task ref="taskPop" v-if="token && wdWidth >= 900" :showFlag="popFlag" @change="flag => { popFlag = flag }" /> -->
    <keep-alive :include="keepRoutes" v-if="isRouterAlive">
      <router-view
        style="padding-top: 5.81rem; min-height: calc(100vh - 5.81rem)"
        :key="key"
      >
        <my-footer style="margin-top: auto" v-show="true || !isMoblie" />
      </router-view>
    </keep-alive>
    <!-- <my-footer v-if="!isMoblie" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _isMobile, random64 } from '@/utils/common'
import myHeader from '@/components/header/index.vue'
import myFooter from '@/components/footer/index.vue'
import Task from '@/components/task/index.vue'
import Login from '@/components/login/index.vue'
import Introduction from '@/components/introduction/index.vue'
import { MetaEthers } from '@/utils/metamask'
export default {
  components: { myHeader, myFooter, Task, Login, Introduction },
  data() {
    return {
      isshowheader: true,
      isRouterAlive: true,
      popFlag: false,
      introductionFlag: false,
      test: '我是app的',
    }
  },
  async created() {
    this.$store.commit('common/LOG_STATE', false)
    // console.log('reloadHandler函数',this.reload)
    this.$store.commit('user/SET_WALLETOBJ', this.reload)
    // console.log(this.wdHeight,_isMobile())
    let deviceID = localStorage.getItem('SPK_deviceId')
    if (!deviceID) {
      deviceID = random64()
      localStorage.setItem('SPK_deviceId', deviceID)
    }
    this.$store.commit('common/SET_DEVICE_ID', deviceID)
    let userInfo = localStorage.getItem('userInfo')
    let token = localStorage.getItem('token')
    let isStarted = localStorage.getItem('isStarted')
    if (userInfo && token) {
      userInfo = JSON.parse(userInfo)
      this.$store.commit('user/SET_INFO', userInfo)
      this.$store.commit('user/SET_TOKEN', token)
      let res = await this.$request({
        path: 'user/getWalletDetail',
        data: { currency_key: 'spk' },
      })
      this.$store.commit('user/SET_SPK', res.data?.value || 0)
    }

    // var loginSeen = this.getCookie('loginSeen')
    // console.log(loginSeen, document.cookie)
    // if (loginSeen !== 'true') {
    //   this.$store.commit('common/LOG_STATE', true)
    //   var d = new Date()
    //   d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000)
    //   var expires = 'expires=' + d.toUTCString()
    //   document.cookie = 'loginSeen=true; ' + expires + '; path=/'
    // } else if (!isStarted && !token) {
    //   this.$router.replace({ name: 'Started' })
    // }
    // if (!isStarted && !token) {
    //   this.$router.replace({ name: 'Started' })
    // }
  },
  beforeMount() {},
  mounted() {
    // this.$nextTick(() => {
    //   console.log(this.$route)
    //   if(this.$route.path){
    //   }
    //   document.getElementsByTagName('html')[0].style.fontSize = '18px'
    // })
  },
  beforeDestroy() {},
  methods: {
    getCookie(cname) {
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    reload() {
      console.log('触发页面刷新', this.$route.meta.refresh)
      if (this.$route.meta.refresh) {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      }
    },
    taskRefresh(refresh = false) {
      console.log('有token吗===', this.$store.state.user.token)
      if (this.$store.state.user.token)
        console.log('taskPop---', this.$refs?.taskPop?.getList(false, refresh))
    },
    clickHandler() {
      // console.log('点击')
    },
    showPop() {
      this.popFlag = true
    },
    showIntroduction() {
      this.introductionFlag = false
    },
  },
  provide() {
    return {
      reload: this.reload,
      taskRefresh: this.taskRefresh,
      showPop: this.showPop,
    }
  },
  computed: {
    ...mapGetters([
      'wdHeight',
      'wdWidth',
      'isMoblie',
      'keepRoutes',
      'showLog',
      'hasPop',
      'token',
      'metaObj',
      'walletObj',
      'firstLog',
      'logState',
      'uid',
    ]),
    key() {
      if (this?.$refs['header'])
        this.$refs['header'].activeIndex = this.$refs[
          'header'
        ].tagList.findIndex((item) => item == this.$route.name)
      console.log('路由名称---', this.$route.name, this.keepRoutes)
      if (this.$route.name == 'Started') {
        this.isshowheader = false
      } else {
        this.isshowheader = true
      }
      return this.$route.name
    },
  },
  watch: {
    keepRoutes: {
      handler(val) {
        console.log('监听变化——---', this.keepRoutes)
      },
    },
    token: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            // !this.introductionFlag && this.showIntroduction();
            // !this.popFlag && this.showPop()
            !this.introductionFlag &&
              this.firstLog &&
              !this.logState &&
              this.showIntroduction()
            !this.popFlag && this.firstLog && !this.logState && this.showPop()
            this.$store.commit('user/SET_LOGSTATE')
          }, 600)
        }
      },
    },
  },
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow-x: hidden;
}

.web3modal-modal-lightbox {
  z-index: 1050 !important;
}

#WEB3_CONNECT_MODAL_ID {
  visibility: hidden !important;
}
</style>

<style lang="scss">
.myMessage {
  border-radius: 1.63rem !important;
  background: rgba(63, 63, 63, 0.25) !important;
  border: 0.06rem solid rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(10px);

  & i:nth-of-type(1) {
    font-size: 1.44rem;
  }

  & i:nth-last-of-type(1) {
    font-size: 1.38rem;
  }

  & p {
    font-size: 1.13rem !important;
    line-height: 1.44rem !important;
    color: #ffffff !important;
    font-family: SVN-GilroyMedium;
  }
}

html >>> .el-message__content {
  font-size: 1.13rem !important;
  line-height: 1.44rem !important;
  color: #ffffff !important;
  font-family: SVN-GilroyMedium;
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 350px !important;
  }
}
</style>

<style lang="scss" scoped>
#app {
  width: 100%;
  background: fixed url('@/assets/bg.png') center/cover no-repeat;
  background-clip: border-box;
  background-color: #0b0b0b;
  box-sizing: border-box;
  border-top: 1px solid transparent;
}
</style>
